<template>
  <div>
    <div class="tabs">
      <ul>
        <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ 'is-active': tab.isActive }"
        >
          <a :href="tab.href" @click="selectTab(tab)" class="tabs__item">
            <component v-if="tab.hasIcon" :is="tab.icon" class="tabs__icon" />
            {{ tab.name }}</a
          >
        </li>
      </ul>
    </div>

    <div class="tabs-details">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasChanges: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabs: [],
    };
  },
  computed: {
    channelSettings() {
      return this.$t('channels-settings');
    },
  },
  created() {
    this.tabs = this.$children;
  },
  methods: {
    selectTab(selectedTab) {
      if (this.isNew) {
        this.$modal.show('new-channel-confirmation', {
          title: this.channelSettings['create-channel'],
          description: this.channelSettings['new-channel-warning'],
          variant: 'default',
        });
      } else if (!this.hasChanges) {
        this.changeTab(selectedTab);
      } else {
        const actualTab = this.tabs.find(tab => tab.isActive);

        if (actualTab.name !== selectedTab.name) {
          this.$modal.show('confirmation', {
            title: this.channelSettings['change-tab'].title,
            description: this.channelSettings['change-tab'].description,
            variant: 'default',
            confirm: () => {
              if (selectedTab.name !== this.channelSettings.detail) {
                this.$emit('change-mode');
              }
              this.$emit('update', null, null, selectedTab.name);
              this.$emit('toggle-change', false);
              this.changeTab(selectedTab);
            },
          });
        }
      }
    },
    changeTab(selectedTab) {
      this.tabs.forEach((tab) => {
        const currentTab = tab;
        currentTab.isActive = tab.name === selectedTab.name;
      });
    },
  },
};
</script>
